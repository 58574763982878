import { useEffect, useState } from "react";
import { ButtonBase, Stack, Typography, Zoom } from "@mui/material";

import { useStore, observer } from "../../../service/mobx";

function Suggestions({ mobile = false }) {
  const [suggestions, setSuggestions] = useState(new Map());
  const { agent, reader } = useStore();
  const lastMessageIsTldr =
    suggestions.size === 3 &&
    agent.history
      .slice(0, 2)
      .some(({ text = "" }) =>
        text.startsWith(`Here's a tldr on **${reader.paperNode?.title}`)
      );

  useEffect(() => {
    if (agent.loaded && agent.paperHasBeenRead && reader.paperNode) {
      const { title } = reader.paperNode;

      setSuggestions(
        new Map([
          [
            "top insights",
            `What are the top new ideas / insights presented in the paper "${title}"`
          ],
          [
            "compare vs alternatives",
            `For the paper "${title}", how do techniques presented compare against alternatives? Help me understand the numerical diff`
          ],
          ["give me a tldr", `Give me a tldr for the paper "${title}"`]
        ])
      );

      return () => setSuggestions(new Map());
    }
  }, [agent.loaded, agent.paperHasBeenRead, reader.paperNode]);

  useEffect(() => {
    if (lastMessageIsTldr) {
      setSuggestions(suggestions => {
        suggestions.delete("give me a tldr");

        return new Map([...suggestions.entries()]);
      });
    }
  }, [agent, lastMessageIsTldr]);

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      height={mobile && suggestions.size === 0 ? 8 : 48}
    >
      {[...suggestions.entries()].map(([label, command]) => (
        <Zoom key={label} in appear style={{ transitionDelay: "1s" }}>
          <ButtonBase
            disabled={agent.running}
            onClick={() => {
              const options = new Map(suggestions);

              agent.chat(command);
              options.delete(label);

              setSuggestions(options);
            }}
            sx={theme => ({
              px: 1,
              py: 0.25,
              border: 1,
              borderColor: "outline.color",
              bgcolor: "primary.container",
              borderRadius: theme.shape.sm
            })}
          >
            <Typography variant="labelSm" color="primary.on.container">
              {label}
            </Typography>
          </ButtonBase>
        </Zoom>
      ))}
    </Stack>
  );
}

export default observer(Suggestions);
