"use client";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { Box } from "@mui/material";
import { autorun } from "mobx";

import { useStore, observer } from "../../service/mobx";
import { getTldrMd } from ".";
import Input from "./Input";
import Messages from "./Messages";
import Suggestions from "./Input/Suggestions";

function AgentClient({ trendingMd, tldrMd }) {
  const {
    reader: { whiteLabelled }
  } = useStore();

  useHooks(trendingMd, tldrMd);

  return (
    <Box
      height="inherit"
      display={whiteLabelled ? "none" : { compact: "none", expanded: "block" }}
      width={{ compact: 360, large: 412, extraLarge: 412 + 80 }}
    >
      <Input />
      <Suggestions />
      <Box
        pr={1}
        pl={{ expanded: 0, large: 1 }}
        pt={{ expanded: 1, large: 2 }}
        // 56px (input) - 48px (suggestions) - 24px (padding)
        height="calc(100% - 56px - 48px + 24px)"
        bgcolor="surface.container.high"
        sx={theme => ({
          overflowY: "scroll",
          direction: "rtl",
          borderRadius: theme.shape.md.top,
          "&::-webkit-scrollbar": {
            width: 16
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: theme.palette.alpha(
              theme.palette.surface.on.variant,
              0.1
            )
          }
        })}
      >
        <Box sx={{ direction: "ltr" }}>
          <Messages />
        </Box>
      </Box>
    </Box>
  );
}

export default observer(AgentClient);

function useHooks(trendingMd, tldrMd) {
  const { agent, reader, user } = useStore();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname === "/") {
      const session = [...agent.session];
      const page = "Discover";
      const firstView = agent.pages.has(page) === false;

      session.push({ page });
      agent.set.session(session);

      if (firstView) {
        return greetTrending(agent, trendingMd);
      }
    } else if (reader.paperID && reader.publisher && reader.paperNode?.title) {
      const session = [...agent.session];
      const page = "Research Paper";
      const firstView = agent.pages.has(page) === false;

      session.push({
        page,
        paperID: reader.paperID,
        publisher: reader.publisher,
        title: reader.paperNode?.title
      });
      agent.set.session(session);

      if (firstView) {
        return greetPaper(agent, tldrMd, reader.publisher, reader.paperID);
      }
    }
  }, [agent, reader, pathname, reader.paperNode?.title, trendingMd, tldrMd]);

  useEffect(() => {
    if (agent.loaded && agent.needsToSayHi) {
      const welcome = {
        id: "welcome",
        role: "assistant",
        text: user.firstExperience
          ? `**Welcome to Bytez.**\n\nI'm an AI agent that's grounded in research.\n\nEvery day, I read hundreds of newly published AI papers. You can ask me about these papers.\n\nI can help you search, explain concepts, and answer your questions about AI models and research.\n\nWhat would you like to learn today?`
          : `Welcome back ${
              user.isAnonymous ? "" : user.name?.split(" ")?.[0] ?? ""
            }`
      };

      agent.set.needsToSayHi(false);
      agent.message.add(welcome, false);
    }
  }, [agent, user, agent.loaded, agent.needsToSayHi]);
}
function greetTrending(agent, trendingMd) {
  if (trendingMd) {
    const { markdown, papers } = trendingMd;

    agent.set.greeting({
      id: "trending",
      role: "assistant",
      text: markdown,
      sources: papers
    });

    return agent.set.greeting;
  }
}
function greetPaper(agent, tldrMd, publisher, paperID) {
  if (tldrMd) {
    agent.set.greeting({ id: "tldr", role: "assistant", text: tldrMd });

    return agent.set.greeting;
  } else {
    const cleanUp = autorun(async () => {
      if (agent.paperHasBeenRead) {
        tldrMd = await getTldrMd(publisher, paperID);

        if (tldrMd) {
          greetPaper(agent, tldrMd);
        }
      }
    });

    return () => {
      cleanUp();
      agent.set.greeting();
    };
  }
}

export const MobileAgent = () => (
  <>
    <Messages mobile />
    <Box
      pb={1}
      px={2}
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      borderTop={1}
      bgcolor="surface.bright"
      borderColor="outline.variant"
    >
      <Suggestions mobile />
      <Input mobile />
    </Box>
  </>
);
