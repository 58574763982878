"use client";
import { useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import { Stack, Typography } from "@mui/material";

import { useStore, observer } from "../../../service/mobx";

const Bytez = dynamic(() => import("./Bytez"));
const User = dynamic(() => import("./User"));
const Sources = dynamic(() => import("./Sources"));
const Citation = dynamic(() => import("./Citation"));

function ChatMessages({ mobile }) {
  const { agent } = useStore();
  const ref = useRef();
  const lastMessage = agent.history[0]?.text;

  useEffect(() => {
    if (mobile) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [mobile, lastMessage]);

  return (
    <Stack
      ref={ref}
      useFlexGap
      spacing={6}
      pb={4}
      direction={mobile ? "column-reverse" : "column"}
      sx={mobile ? { overflowY: "auto", px: 2, pb: 15 } : undefined}
    >
      {agent.history.map(({ id, role, text, citations, sources, step }) =>
        role === "user" ? (
          <User key={id} text={text} />
        ) : (
          <div key={id}>
            <Bytez id={id} text={text} step={step} />
            {citations?.length ? (
              <Citation citations={citations} sources={sources} />
            ) : sources?.length ? (
              <Sources sources={sources} />
            ) : null}
          </div>
        )
      )}
    </Stack>
  );
}

export default observer(ChatMessages);
export const AvatarGroup = ({ Avatar, name }) => (
  <Stack spacing={1.5} direction="row" alignItems="center">
    <Avatar />
    <Typography variant="labelLgProminent" color="surface.on.variant">
      {name}
    </Typography>
  </Stack>
);
